import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { handleFolderNameLength } from "../Global/FileResponseChanger";
import "./../css/project_folder.css";

const useStyles = makeStyles({
  grid: {
    border: "1px solid #BABEC2",
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    margin: 0,
    borderRadius: "5px",
    "&:hover": {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      transitionDuration: "0.3s",
    },
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    // justifyContent: 'center',
    padding: "5px 10px",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

function Folder({ icon, file }) {
  const classes = useStyles();
  return (
    <Box sx={{ bgcolor: "white" }} className={classes.grid} container>
      <Item elevation={0} sx={{ mt: "5px", color: "#313949" }}>
        {icon}
      </Item>
      <Item
        className={classes.item}
        elevation={0}
        sx={{
          ml: "5px",
          pb: 0,
          display: "inline",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#313949",
          fontWeight: 400,
        }}
      >
        <Typography variant="h4" sx={{ fontSize: "16px !important" }}>
          {handleFolderNameLength(file)}
        </Typography>
      </Item>
    </Box>
  );
}

export default Folder;
