import React, { useEffect, useState } from "react";
import { getModuleAndLookupDataAndFields } from "./Functions/helperFunctions";
import MuiTextField from "./MuiTextField";

function DynamicHashTextField({
  module,
  fields = [],
  savedData,
  initialValue,
}) {
  const [moduleFields, setModuleFields] = useState(fields);
  useEffect(() => {
    async function fetchData() {
      if (module && module !== "" && fields?.length == 0) {
        async function getRecordAndFieldData() {
          try {
            const entityDataAndFields = await getModuleAndLookupDataAndFields(
              module
            );
            let allFieldsData = [];
            Object.keys(entityDataAndFields?.fieldNames).forEach((module) => {
              allFieldsData = [
                ...allFieldsData,
                ...entityDataAndFields?.fieldNames[module],
              ];
            });
            // ;
            setModuleFields(allFieldsData);
          } catch (error) {
            // ;
          }
        }
        getRecordAndFieldData();
      }
    }
    fetchData();
  }, [module]);

  return (
    <div>
      <MuiTextField
        moduleFields={moduleFields}
        initialValue={initialValue}
        savedData={savedData}
      />
    </div>
  );
}

export default DynamicHashTextField;
