import { proxy } from "valtio";
import { devtools } from "valtio/utils";

// const Leads = {
//     moduleApiName: "Leads",
//     rootFolder: {
//         id: "2yijq48ffa85cf7c84c35a2bc331fb43943b9",
//         type: "folder",
//         is_folder: true,
//         parent_id: "9o692011314268a294c82a8170766f2bfb935",
//         name: "Current FOlder",
//         extn: "",
//     },
//     treeData: [
//         {
//             title: "${Full_Name}",
//             id: 0,
//             expanded: true,
//             children: [
//                 {
//                     title: "Documents",
//                     id: 1,
//                 },
//             ],
//         },
//     ],
//     fieldApiName: {
//         display_name: "Workdrive Folder Id",
//         api_name: "Workdrive_Folder_Id",
//     },
//     status: true,
// };

// const Deals = {
//     moduleApiName: "Deals",
//     rootFolder: {
//         id: "cptya81ea729c61c6471ebef9be4777e1de3c",
//         type: "folder",
//         is_folder: true,
//         parent_id: "cptyaaaef14c57a2f4f64af81f805e5607f54",
//         name: "Tazik",
//         extn: "",
//     },
//     fieldApiName: {
//         display_name: "Workdrive Folder Id",
//         api_name: "Workdrive_Folder_Id",
//     },
//     treeData: [
//         {
//             title: "${Deal_Name}",
//             id: 0,
//             expanded: true,
//             children: [
//                 {
//                     title: "Documents",
//                     id: 1,
//                 },
//                 {
//                     title: "sds",
//                     id: 1,
//                 },
//                 {
//                     title: "sdfs",
//                     id: 1,
//                 },
//             ],
//         },
//     ],
//     status: false,
// };

// const Contacts = {
//     moduleApiName: "Contacts",
//     rootFolder: {
//         id: "9o692011314268a294c82a8170766f2bfb935",
//         type: "folder",
//         is_folder: true,
//         parent_id: "9o6925777cf7370584570b14fe390238aac06",
//         name: "TESTT",
//         extn: "",
//     },
//     fieldApiName: {
//         display_name: "Workdrive Folder Id",
//         api_name: "Workdrive_Folder_Id",
//     },
//     treeData: [
//         {
//             title: "${Full_Name}",
//             id: 0,
//             expanded: true,
//             children: [
//                 {
//                     title: "Documents",
//                     id: 1,
//                 },
//             ],
//         },
//     ],
//     status: true,
// };

// const Accounts = {
//     moduleApiName: "Accounts",
//     rootFolder: {
//         id: "cptya63002d4bc7bb4650a61e8eec8969c4c1",
//         type: "folder",
//         is_folder: true,
//         parent_id: "cptyaaaef14c57a2f4f64af81f805e5607f54",
//         name: "Tanvir Mallik",
//         extn: "",
//     },
//     fieldApiName: {
//         display_name: "Workdrive Folder Id",
//         api_name: "Workdrive_Folder_Id",
//     },
//     treeData: [
//         {
//             title: "${Account_Name}",
//             id: 0,
//             expanded: true,
//             children: [
//                 {
//                     title: "Documents",
//                     id: 1,
//                 },
//             ],
//         },
//     ],
//     status: false,
// };

const Leads = {};

const Deals = {};

const Contacts = {};

const Accounts = {};

const Campaigns = {};

const widState = proxy({
  authCheck: false,
  orgId: "",
  apiKey: "",
  zuid: "",
  connName: "easyworkdriveforcrm",
  bread: [{ name: "My Folder", id: "0fx6ef888f9bfcdb040bd9084653db3c65a8c" }],
  settingData: {
    Leads: Leads,
    Contacts: Contacts,
    Accounts: Accounts,
    Deals: Deals,
    Campaigns: Campaigns,
  },
  setInitialSetting: (data) => {
    widState.settingData = data;
  },
  setSettingData: (data) => {
    const { key, response } = data;
    widState.settingData = {
      ...widState.settingData,
      [widState.selectedSetting]: {
        ...widState.settingData?.[widState.selectedSetting],
        [key]: response,
      },
    };
  },
  stringData: {
    Leads: JSON.stringify(Leads),
    Deals: JSON.stringify(Deals),
    Contacts: JSON.stringify(Contacts),
    Accounts: JSON.stringify(Accounts),
  },
  setInitialString: (data) => {
    widState.stringData = data;
  },
  setStringData: () => {
    widState.stringData = {
      ...widState.stringData,
      [widState.selectedSetting]: JSON.stringify(
        widState.settingData?.[widState.selectedSetting]
      ),
    };
  },
  selectedSetting: "Accounts",
  setSelectedSetting: (data) => {
    widState.selectedSetting = data;
  },
  apiData: {},
  setApiData: (data) => {
    const { folderID, folderResp } = data;
    widState.apiData = {
      ...widState.apiData,
      [folderID]: folderResp,
    };
  },
  addFolder: (data) => {
    const { folderID, response } = data;
    widState.apiData = {
      ...widState.apiData,
      [folderID]: [].concat(widState.apiData?.[folderID], [response]),
    };
  },
  folderDetails: {},
  setFolderDetails: (data) => {
    const { folderID, folderResp } = data;
    widState.folderDetails = {
      ...widState.folderDetails,
      [folderID]: folderResp,
    };
  },
  breadCrumbs: [],
  decreaseBreadCrumbs: (folder) => {
    const index = widState.breadCrumbs.findIndex((object) => {
      return object.id === folder?.id;
    });

    if (index === -1) {
      widState.breadCrumbs = [folder];
    } else {
      widState.breadCrumbs.length = index + 1;
    }
  },
  increaseBreadCrumbs: (folder) => {
    widState.breadCrumbs = {
      ...widState.breadCrumbs,
      folder,
    };
  },
  loading: true,
  setLoading: (bool) => {
    widState.loading = bool;
  },
  snackbar: {
    open: false,
    severity: "success",
    message: "This is a success message",
  },
  setSnackbar: (data) => {
    widState.snackbar = {
      open: data?.bool,
      severity: data?.type,
      message: data?.message,
    };
  },
  isError: false,
  setIsError: (bool) => {
    widState.isError = bool;
  },
  errorMessage: "",
  setErrorMessage: (errorMessage) => {
    widState.errorMessage = errorMessage;
  },

  setRootBread: (id) => {
    widState.bread = [{ name: "Base Directory", id: id }];
  },
  setBreadCrumbs: (folder) => {
    widState.bread = [].concat(widState.bread, [
      { name: folder?.name, id: folder?.id },
    ]);
  },
  setBreadCrumbsUrl: (folder) => {
    let my_array = [];
    for (let index = 0; index < widState.bread.length; index++) {
      const element = widState.bread[index];
      if (element.id !== folder.id) my_array.push(element);
      else {
        my_array.push(element);
        break;
      }
    }
    widState.bread = my_array;
  },
  setNewBreadCrumbsUrl: (breadCrumbsArray) => {
    let my_array = [];
    let connectionFound = false;
    breadCrumbsArray.forEach((newFile) => {
      if (!connectionFound) {
        const { name, id } = widState?.bread?.find(
          ({ id }) => id === newFile?.id
        );
        if (id) {
          connectionFound = true;
          my_array.push({ name: newFile?.name, id: newFile?.id });
        }
      } else {
        my_array.push({ name: newFile?.name, id: newFile?.id });
      }
    });
    
    widState.bread = my_array;
  },
  setView: (bool) => {
    widState.view = bool;
  },
  setId: (id) => {
    widState.id = id;
  },
  setPasteOpen: (bool) => {
    widState.pasteOpen = bool;
  },
  setInitializeData: (settings) => {
    let tempData = {};
    settings.forEach((setting) => {
      tempData[setting?.settingId] = {
        ...widState?.settingData?.[setting?.settingId],
        ...{
          rootFolderId: setting?.rootFolderId,
          name: setting?.Name,
          breadCrumbs: [],
          previousData: {},
          view: true,
          thumbnailUrls: {},
          downloadUrls: {},
        },
      };
    });
    widState.settingData = tempData;
  },
  // setApiSettingData: (settingId, folder, apiData) => {
  //   let folderId = folder?.id ? folder?.id : folder;
  //   let tempData = {
  //     ...widState.settingData,
  //     [settingId]: {
  //       ...widState.settingData?.[settingId],
  //       previousData: {
  //         ...widState.settingData?.[settingId]?.previousData,
  //         [folderId]: [...apiData],
  //       },
  //       breadCrumbs: [].concat(widState.settingData?.[settingId]?.breadCrumbs, [
  //         {
  //           name: folder?.name ? folder.name : "My Folder",
  //           id: folderId,
  //         },
  //       ]),
  //     },
  //   };
  //   widState.settingData = tempData;
  // },
  setApiSettingData: (settingId, folder, apiData) => {
    const folderId = folder?.id ? folder?.id : folder;
    widState.settingData[settingId].previousData[folderId] = [
      ...(widState.settingData[settingId].previousData[folderId] || []),
      ...apiData,
    ];
    widState.settingData[settingId].breadCrumbs = [
      ...(widState.settingData[settingId].breadCrumbs || []),
      {
        name: folder?.name || "My Folder",
        id: folderId,
      },
    ];
  },
  setLoadedFolderBreadCrumbs: (settingId, folder, apiData) => {
    const folderId = folder?.id ? folder?.id : folder;
    widState.settingData[settingId].previousData[folderId] = [...apiData];
    widState.settingData[settingId].breadCrumbs = [
      ...(widState.settingData[settingId].breadCrumbs || []),
      {
        name: folder?.name || "My Folder",
        id: folderId,
      },
    ];
  },
  setBreadCrumbsSettingData: (settingId, folder) => {
    let my_array = [];
    for (
      let i = 0;
      i < widState.settingData?.[settingId].breadCrumbs.length - 1;
      i++
    ) {
      const element = widState.settingData?.[settingId].breadCrumbs?.[i];
      if (element.id !== folder.id) my_array.push(element);
      else {
        my_array.push(element);
        break;
      }
    }
    widState.settingData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        breadCrumbs: my_array,
      },
    };
  },
  setViewSettingData: (settingId, bool) => {
    widState.settingData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        view: bool,
      },
    };
  },
  setAddItemSettingData: (settingId, folder, apiData) => {
    let folderId = folder?.id ?? folder;
    widState.settingData[settingId].previousData[folderId] = apiData;
  },
  setThumbNail: (settingId, file, thumbnail_url) => {
    widState.settingData[settingId].thumbnailUrls[file?.id] = thumbnail_url;
  },
  setDownloadedImage: (settingId, file, download_url) => {
    widState.settingData[settingId].downloadUrls[file?.id] = download_url;
  },
});
const unsub = devtools(widState, "Drive Manager State");
export { widState };
