import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import EditAction from "./EditAction/EditAction";
import DeleteAction from "./DeleteAction/DeleteAction";
import { useSnapshot } from "valtio";
import { widState } from "../../Store/workdriveState";
import folderAddIcon from "../../Images/Folder-create.png";
import folderEditIcon from "../../Images/Folder-edit.png";
import folderDeleteIcon from "../../Images/Folder-delete.png";
import easyPlugins from "../../Images/easypluginz-label.png";
import CreateActionNew from "./CreateAction/CreateActionNew";
import axios from "axios";

const ZOHO = window.ZOHO;

const customHeading = {
  fontWeight: 500,
  fontSize: "16px",
  color: "rgba(0, 0, 0, 0.87)",
};
const customDescription = {
  fontSize: "16px",
  color: "rgba(0, 0, 0, 0.6)",
};

export default function CustomActionComponents() {
  const snap = useSnapshot(widState);
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [configData, setConfigData] = useState();
  const [moduleName, setModuleName] = useState(null);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [page, setPage] = useState(null);
  const [existingSettingData, setExistingSettingData] = useState(null);
  const [dataCenterUrl, setDataCenterurl] = useState("");

  useEffect(() => {
    const fetchOrgVariablesData = async () => {
      /**
       * Get Organization Variables
       */
      widState.connName = "easyworkdriveforcrm";
      const orgData = {
        apiKeys: [
          "easyworkdriveforcrm__API_KEY",
          "easyworkdriveforcrm__Organization_ID",
          "easyworkdriveforcrm__ZAPI_Key",
          "easyworkdriveforcrm__Data_Center_URL",
          "easyworkdriveforcrm__ZUID",
        ],
      };
      const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);
      widState.apiKey =
        orgVariables?.Success?.Content?.easyworkdriveforcrm__API_KEY?.value;
      widState.orgId =
        orgVariables?.Success?.Content?.easyworkdriveforcrm__Organization_ID?.value;
      widState.dataCenterUrl =
        orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL?.value;
      setDataCenterurl(
        orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
          ?.value
      );

      /**
       * Fetch User Details
       */
      // const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();
      // widState.zuid = orgResp?.org?.[0]?.primary_zuid;

      widState.zuid =
        orgVariables?.Success?.Content?.easyworkdriveforcrm__ZUID?.value;
        };
    
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        setConfigData(data?.configdata);

        setModuleName(data?.module);

        await fetchOrgVariablesData();
        if (data?.configdata?.settingId) {
          const existingSettingResp = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings/${data.configdata.settingId}`,
            method: "GET",
            headers: {
              orgid: widState.orgId,
              apikey: widState.apiKey,
              datacenterurl: dataCenterUrl,
              connname: "easyworkdriveforcrm",
            },
          });

          setExistingSettingData(existingSettingResp.data?.data);
        }

        await ZOHO.CRM.META.getFields({ Entity: data?.module }).then(function (
          data
        ) {
          let fieldArray = data?.fields;
          let filteredFieldArray = fieldArray?.filter(
            (item) =>
              (item.data_type === "text" ||
                item.data_type === "website" ||
                item.data_type === "textarea") &&
              item.json_type === "string"
          );

          setFieldOptions(filteredFieldArray || []);
        });
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    if (!zohoLoaded) {
      initZoho();
    }
  }, []);

  if (zohoLoaded) {
    if (configData) {
      if (configData.actionType === "Create Action") {
        return (
          <CreateActionNew
            moduleName={moduleName}
            fieldOptions={fieldOptions}
            existingData={existingSettingData}
          />
        );
      } else if (configData.actionType === "Edit Action") {
        return (
          <EditAction
            fieldOptions={fieldOptions}
            moduleName={moduleName}
            existingData={existingSettingData}
          />
        );
      } else if (configData.actionType === "Delete Action") {
        return (
          <DeleteAction
            moduleName={moduleName}
            fieldOptions={fieldOptions}
            existingData={existingSettingData}
          />
        );
      }
    } else {
      if (!page) {
        return (
          <Box
            sx={{
              display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              px: 4,
              // pt: 2,
            }}
          >
            <Box>
              <Box sx={{ py: 2 }}>
                {/* <Typography sx={{ mb: 1 }}>
                  Lorem ipsum dolor sit amet.
                </Typography> */}
                <Typography>Please select one Option</Typography>
              </Box>
              <Box
                onClick={() => setPage("create")}
                sx={{
                  display: "flex",
                  p: 4,
                  my: 2,
                  height: 40,
                  alignItems: "center",
                  border: "1px solid #c9cdd4",
                  borderRadius: "7px",
                  cursor: "pointer",
                  gap: 2,
                  "&:hover": {
                    bgcolor: "rgba(24, 80, 160, 0.04)",
                  },
                }}
              >
                <img src={folderAddIcon} alt="folder add icon" />
                <Box>
                  <Typography sx={customHeading}>
                    Create Workdrive Folder
                  </Typography>
                  <Typography sx={customDescription}>
                    Creates Workdrive Folder and allows you to create Nested Sub
                    Folders on Workflow Execution
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={() => setPage("edit")}
                sx={{
                  display: "flex",
                  p: 4,
                  mb: 2,
                  height: 40,
                  alignItems: "center",
                  border: "1px solid #c9cdd4",
                  borderRadius: "7px",
                  cursor: "pointer",
                  gap: 2,
                  "&:hover": {
                    bgcolor: "rgba(24, 80, 160, 0.04)",
                  },
                }}
              >
                <img src={folderEditIcon} alt="folder edit icon" />
                <Box>
                  <Typography sx={customHeading}>Edit Folder Name</Typography>
                  <Typography sx={customDescription}>
                    Edit Folder Name to given format on Workflow Execution
                  </Typography>
                </Box>
              </Box>

              <Box
                onClick={() => setPage("delete")}
                sx={{
                  display: "flex",
                  p: 4,
                  height: 40,
                  alignItems: "center",
                  border: "1px solid #c9cdd4",
                  borderRadius: "7px",
                  cursor: "pointer",
                  gap: 2,
                  "&:hover": {
                    bgcolor: "rgba(24, 80, 160, 0.04)",
                  },
                }}
              >
                <img src={folderDeleteIcon} alt="folder edit icon" />
                <Box>
                  <Typography sx={customHeading}>
                    Delete Workdrive Folder
                  </Typography>
                  <Typography sx={customDescription}>
                    Delete Workdrive Folder and all its Subfolders on Workflow
                    Execution
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                position: "fixed",
                bottom: 10,
                right: 5,
              }}
            >
              <img
                src={easyPlugins}
                alt="Easy plugins logo"
                height={40}
                // style={{ marginLeft: "90px" }}
              />
              {/* <img src={easyPlugins} alt="easy plugins logo" width={220} /> */}
            </Box>
          </Box>
        );
      }
      if (page === "create") {
        return (
          // <CreateAction
          //   moduleName={moduleName}
          //   fieldOptions={fieldOptions}
          //   setPage={setPage}
          // />
          <CreateActionNew
            moduleName={moduleName}
            fieldOptions={fieldOptions}
            setPage={setPage}
          />
        );
      }
      if (page === "edit") {
        return (
          <EditAction
            fieldOptions={fieldOptions}
            moduleName={moduleName}
            setPage={setPage}
          />
        );
      }
      if (page === "delete") {
        return (
          <DeleteAction
            moduleName={moduleName}
            fieldOptions={fieldOptions}
            setPage={setPage}
          />
        );
      }
    }
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
}
