import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function Overview({
  authCheck,
  authLoading,
  setDataCenterUrl,
  handleAuthenticate,
  dataCenterUrl,
  handleRevoke,
}) {
  const dataCenterUrlOptions = [
    "https://accounts.zoho.com",
    "https://accounts.zoho.eu",
    "https://accounts.zoho.com.au",
    "https://accounts.zoho.in",
    "https://accounts.zoho.com.cn",
    "https://accounts.zoho.jp",
  ];
  // const [disable, setDisable] = useState(!dataCenterUrl);
  return (
    <Box
      sx={{
        py: 1,
        width: "85%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mt: 2,
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          Authentication
        </Typography>
        <Divider />
        <br />
        {/* <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Overview
        </Typography> */}
        {/* <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",

            "li:not(:last-child)": {
              margin: "0 0 10px 0",
            },
          }}
        >
          ZOHO Workdrive is a standalone app of ZOHO Apps, which needs to
          integrate using Rest API. To do Read/Create/Edit/Delete tasks in ZOHO
          Workdrive you need to authenticate the service once to make
          Widgets/Workflow functional
        </Box> */}
        <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Select Data Center URL
        </Typography>
        <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            linetHeight: "24px",
          }}
        >
          Based on different Zoho Data Center you need to select appropriate
          Data Center URL and Hit Authenticate and follow the steps to proceed.
        </Box>
      </Box>
      <Box sx={{ width: "40%", ml: 7, mt: 3 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataCenterUrlOptions}
          defaultValue={
            dataCenterUrl
              ? dataCenterUrl.includes("https://www.zohoapis")
                ? `https://accounts.zoho.${
                    dataCenterUrl?.split("https://www.zohoapis.")?.[1]
                  }`
                : dataCenterUrl
              : ""
          }
          onChange={(e, v) => {
            if (v) {
              setDataCenterUrl(
                `https://www.zohoapis.${
                  v?.split("https://accounts.zoho.")?.[1]
                }`
              );
              // setDisable(false);
            } else {
              setDataCenterUrl();
              // setDisable(true);
            }
          }}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Center Url"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        {!authCheck && (
          <Button
            disabled={
              authCheck ||
              !dataCenterUrl?.includes("https://www.zohoapis") ||
              authLoading
            }
            onClick={() => {
              handleAuthenticate();
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              textTransform: "none",
              width: "11rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {authLoading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Verifying{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Authenticate"
            )}
          </Button>
        )}
        {authCheck && (
          <Button
            disabled={!authCheck}
            onClick={() => {
              handleRevoke();
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              textTransform: "none",
              width: "11rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {authLoading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Revoking...{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Revoke"
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
}
