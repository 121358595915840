import axios from "axios";
const base_url = process.env.REACT_APP_API_SERVER_URL;
const setting_url = `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`;

const headers = {
  Accept: "*/*",
  "Content-Type": "application/json",
};
// TODO connname, orgid, apikey should get from CRM variable
export const getTeamOfUser = async (
  zuid,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/zohoworkdrive/${zuid}/team`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
  };

  try {
    const folderResp = await axios(reqOptions);

    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const getTeamsFolder = async (
  zuid,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  // http://localhost:3041/v2/zohoworkdrive/2wbrkba0d1d851c3f489692419b59db7c6180/files/team
  let reqOptions = {
    url: `${base_url}/zohoworkdrive/${zuid}/files/team`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
  };

  try {
    const folderResp = await axios(reqOptions);

    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const getSettingData = async (
  moduleApiName = "",
  settingType = "",
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let params = {};

  if (moduleApiName != "") {
    params["moduleApiName"] = moduleApiName;
  }

  if (settingType != "") {
    params["settingType"] = settingType;
  }

  let reqOptions = {
    url: `${setting_url}`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    params: params,
  };

  try {
    const settingResp = await axios(reqOptions);

    return settingResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const upsertData = async (
  data = {},
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${setting_url}`,
    method: "POST",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  try {
    const folderResp = await axios(reqOptions);
    // ;
    return folderResp?.data;
  } catch (error) {
    // ;
    return { error: error?.response?.statusText };
  }
};
// upsertData
// TODO connname, orgid, apikey should get from CRM variable

export const getFoldersData = async (
  folder_id,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/${settingUrl?.split("/")?.[1]}/${folder_id}`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
  };

  // ;
  try {
    const folderResp = await axios(reqOptions);
    // ;
    return folderResp?.data;
  } catch (error) {
    // ;
    return { error: error?.response?.statusText };
  }
};

export const getFoldersItem = async (
  folder_id,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/${settingUrl?.split("/")?.[1]}/${folder_id}/files`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    params: {
      sort: "name"
    }
  };

  // ;
  try {
    const folderResp = await axios(reqOptions);
    // ;
    return folderResp?.data;
  } catch (error) {
    // ;
    return { error: error?.response?.statusText };
  }
};

export const shareFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${file.id}/share`;

  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: connname,
    apikey: apikey,
    datacenterurl,
  };

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: headersList,
    data: data,
  };

  return axios(reqOptions)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const shareFullAccess = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${
    file.id
  }/share/fullaccess`;

  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: connname,
    apikey: apikey,
    datacenterurl,
  };

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: headersList,
    data: data,
  };

  return axios(reqOptions)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const createFolder = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${folder_id}`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  // ;
  return axios(reqOptions)
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fileUploader = (
  data,
  parentId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${parentId}`;

  return axios
    .post(URL, data, {
      headers: {
        connname: connname,
        apikey: apikey,
        datacenterurl,
        orgid: orgid,
        datacenterurl,
        Accept: "application/vnd.api+json",
      },
    })
    .then((response) => response?.data)
    .catch((error) => {
      // // ;
      throw error;
    });
};
// Working
// TODO Rename folder for dropbox issue -> may be because of changing of id
export const renameFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  // const URL = `${base_url}/v1/files/${file.id}`;

  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${file.id}`;
  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: connname,
    apikey: apikey,
    datacenterurl,
  };
  let reqOptions = {
    url: URL,
    method: "PUT",
    headers: headersList,
    data: {
      name: file?.extn ? `${data.name}.${file?.extn}` : data.name,
      parent_id: file?.parent_id,
    },
  };
  return axios(reqOptions)
    .then((resp) => {
      // // ;
      return resp.data;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};

// Working
export const deleteFileFolder = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${file.id}`;

  return axios
    .delete(URL, {
      headers: {
        connname: connname,
        apikey: apikey,
        datacenterurl,
        orgid: orgid,
        datacenterurl,
        Accept: "application/vnd.api+json",
      },
    })
    .then((response) => response?.data)
    .catch((error) => {
      // // ;
      throw error;
    });
};
// Working
export const moveFile = (
  file,
  child,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${child?.id}/move`;

  return axios
    .post(
      URL,
      {
        destFolderId: file.id,
        parent_id: child.parent_id,
      },
      {
        headers: {
          connname: connname,
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};


export const getBreadCrumbs = (
  fileId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${
    settingUrl?.split("/")?.[1]
  }/${fileId}/breadCrumbs`;

  return axios({
    method: "get",
    url: URL,
    headers: {
      connname: connname,
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      Accept: "application/vnd.api+json",
    }
  })
    .then((response) => {
      
      return response?.data;
    })
    .catch((error) => {
      
      throw error;
    });
};

export const searchInsideFolder = (
  fileId,
  query,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${
    settingUrl?.split("/")?.[1]
  }/${fileId}/search/insideFolder`;

  return axios({
    method: "post",
    url: URL,
    headers: {
      connname: connname,
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      Accept: "application/vnd.api+json",
    },
    data: {
      query: query,
    },
  })
    .then((response) => {
      
      return response?.data;
    })
    .catch((error) => {
      
      throw error;
    });
};
// Working
export const copyFile = (
  file,
  childId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${childId}/copy`;
  return axios
    .post(
      URL,
      {
        destFolderId: file.id,
      },
      {
        headers: {
          connname: connname,
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      // // ;
      return response?.data;
    })
    .catch((error) => {
      // // ;
      // throw error;
    });
};

export const getImageResponse = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${file.id}/download`;
  return axios({
    method: "get",
    headers: {
      Accept: "application/vnd.api+json",
      connname: connname,
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    responseType: "blob",
    url: URL,
  })
    .then((response) => {
      // // ;
      return response;
    })
    .catch((error) => {
      // // ;
      // throw error;
    });
};

export const getThumbnailData = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${settingUrl?.split("/")?.[1]}/${file.id}/thumbnail`;

  return axios({
    method: "get",
    headers: {
      connname: connname,
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    responseType: "blob",
    url: URL,
  })
    .then((response) => {
      var blodData = new Blob([response.data]);
      return response;
    })
    .catch((error) => {
      return;
    });
};

export const syncAttachment = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${
    settingUrl?.split("/")?.[1]
  }/${folder_id}/sync/acctachment`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  // ;
  return axios(reqOptions)
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};
