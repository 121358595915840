import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import * as ApiCall from "./../../API/ApiCalling";

export default function CreateNewFolderModal({
  handleCloseNew,
  setPost,
  currentFolder,
  addFolder,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
}) {
  const [message, setMessage] = useState("");
  const [createFolderLoading, setCreateFolderLoading] = useState(false);

  const handleChange = (event) => {
    setMessage(event.target.value);

    // ;
  };

  const createFolder = async () => {
    if (!message?.length || !currentFolder?.id) {
      return; // ;
    }

    if (currentFolder?.id) {
      try {
        setCreateFolderLoading(true);
        const createWorkdriveFolder = await ApiCall.createFolder(
          currentFolder?.id,
          {
            name: message,
          },
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );

        if (createWorkdriveFolder?.error) {
          return;
        }

        setPost((prev) => {
          return [...prev, createWorkdriveFolder?.data?.[0]];
        });

        addFolder({
          folderID: currentFolder?.id,
          response: createWorkdriveFolder?.data?.[0],
        });
        setCreateFolderLoading(false);
        handleCloseNew();
        // ;
      } catch (error) {
        // ;
      }
    }
  };

  return (
    <Box sx={{ mx: 3.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          onClick={handleCloseNew}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            p: 0.5,
            cursor: "pointer",
            "&:hover": { bgcolor: "rgba(0, 0, 0, 0.06)" },
          }}
        >
          <ClearIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 27 }} />
        </Box>
      </Box>
      <Box sx={{ mr: 2, mt: 1.5 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 16, mb: 2 }}>
          Folder Name
        </Typography>

        <TextField
          onChange={handleChange}
          value={message}
          size="small"
          fullWidth
          id="fullWidth"
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            onClick={createFolder}
            sx={{
              width: "5rem",
              borderRadius: "4px",
              textTransform: "none",
              height: "2.3rem",
              bgcolor: "#1976D2",
              fontWeight: 400,
            }}
            variant="contained"
          >
            {createFolderLoading ? (
              <CircularProgress sx={{ color: "white" }} size={16} />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
