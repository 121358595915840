import { Typography, Box, Modal, Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useEffect, useState } from "react";
import no_folder from "../../../../../../Images/no-items.png";
import * as ApiCall from "../../../../../API/ApiCalling";
import { widState } from "../../../store/workdriveState";
import { useSnapshot } from "valtio";
import CommonComponent from "../CommonSection/CommonComponent";
import ListItems from "../CommonSection/ListItems";
import DetailsView from "../CommonSection/DetailsView";
import DetailsSearchView from "./DetailsSearchView";

const selectedStyle = {
  display: "flex",
  alignItems: "center",
  border: "1.5px solid #1F6DDE",
  color: "#1F6DDE",
  borderRadius: "4px",
  cursor: "pointer",
  height: "2.3rem",
  "&:hover": { boxShadow: 1 },
};

const notSelectedStyle = {
  display: "flex",
  alignItems: "center",
  border: "1px solid #BABEC2",
  borderRadius: "4px",
  cursor: "pointer",
  height: "2.3rem",
  "&:hover": { boxShadow: 1 },
  color: "#696969",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  py: 3,
  px: 2,
};

export default function SearchDialog({
  searchOpen,
  setSearchOpen,
  searchedItem,
  setSearchedItem,
  setPost,
  settingUrl,
  handleClick,
  datacenterurl,
  connname,
  orgid,
  apikey,
  settingId,
  setDetailsViewPost,
  moveCopyData,
  pasteDatainDetailsView,
  setStep,
  settingData,
  saveApiData,
  module,
  saveData,
  apiData,
  folderDetails,
  setFolderDetails,
  addFolder,
  zuid,
}) {
  const handleOpen = () => setSearchOpen(true);
  const handleClose = () => setSearchOpen(false);

  const snap = useSnapshot(widState);

  const handleDoubleClick = async (data) => {
    if (!data?.id) {
      return;
    }
    // setLoadingPost((prev) => true);

    snap.setLoading(true);
    if (data?.type === "folder") {
      // TODO Fetch BreadCrumbs, Open folder in main view and setPost
      // Get BreadCrumbs
      try {
        snap.setLoading(true);
        const folderBreadCrumbs = await ApiCall.getBreadCrumbs(
          data?.id,
          connname,
          orgid,
          apikey,
          datacenterurl
        );

        if (folderBreadCrumbs?.error) {
          // TODO: show errror
          return;
        }

        const listFiles = await ApiCall.getFoldersItem(
          data?.id,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );
        if (listFiles?.error) {
          //TODO show error
          return;
        }
        widState?.setSearchedApiSettingData(settingUrl, data, listFiles?.data, [
          ...folderBreadCrumbs?.data,
          { name: data?.name, id: data?.id },
        ]);
        setPost((prev) => listFiles?.data);
        setSearchOpen(false);
      } catch (error) {}
      snap.setLoading(false);
      return;
    }
  };

  return (
    <Modal
      open={searchOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
       // add custom css here
      sx={{
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <Box sx={style}>
        {" "}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 100,
          }}
          open={snap.loading}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
        <Grid
          sx={{ py: 1 }}
          alignItems="center"
          container
          maxWidth="lg"
          spacing={2}
        >
          <DetailsSearchView
            searchedItem={searchedItem}
            handleClick={handleClick}
            connname={connname}
            orgid={orgid}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingId={settingId}
            handleDoubleClick={handleDoubleClick}
            setDetailsViewPost={setDetailsViewPost}
            moveCopyData={moveCopyData}
            pasteDatainDetailsView={pasteDatainDetailsView}
          />

          {/* <DetailsView
            post={searchedItem}
            filteredData={searchedItem}
            handleClick={(row, rowId) => {
              handleDoubleClick(row)
              // handleClose()
            }}
            datacenterurl={datacenterurl}
            connname={connname}
            orgid={orgid}
            apikey={apikey}
            settingId={settingId}
            setDetailsViewPost={setDetailsViewPost}
            moveCopyData={moveCopyData}
            pasteDatainDetailsView={pasteDatainDetailsView}
          /> */}
        </Grid>
      </Box>
    </Modal>
  );
}
