import * as React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

function RenameDetailsModal({
  open,
  handleClose,
  handleSubmit,
  control,
  onSubmit,
  file,
}) {
  const [name, setName] = React.useState(file?.name?.split(".")?.[0]);

  useEffect(() => {
    setName(file?.name?.split(".")?.[0]);
  }, [file]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            // width: "100%",
            width: 500, // Set your width here
            padding: "7px",
          },
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 22, p: 2.2, pb: 0 }}>{"Rename"}</Typography>
        <CloseIcon
          onClick={handleClose}
          sx={{ mr: 2, mt: 2, cursor: "pointer", color: "#8a8a8a" }}
        />
      </Box>

      <DialogContent sx={{ p: 2 }}>
        <TextField
          sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
          id="outlined-controlled"
          label="Rename"
          size="small"
          value={name}
          fullWidth
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <Button
            sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
            onClick={handleClose}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            sx={{ marginTop: "10px", bgcolor: "#1976D2" }}
            onClick={() => {
              onSubmit({ name: name });
            }}
            variant="contained"
          >
            Rename
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default RenameDetailsModal;
