import { BackdropRoot } from "@mui/material";

export const changeParent = (file, parent_id) => {
  return {
    ...file,
    attributes: {
      ...file?.attributes,
      destination_id: parent_id,
      parent_id: parent_id,
    },
  };
};
