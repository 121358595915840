import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import {} from "../CommonSection/Response";

import * as ApiCall from "../../../../../API/ApiCalling";
import "./../css/project_folder.css";
import ModalPdf from "./../Modals/ModalPdf";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import useFileNameLength from "../CustomHooks/useFileNameLength";

const CustomImage = styled("img")(({ theme }) => ({
  height: "145px",
  minWidth: "100%",
  objectFit: "fill",
  backgroundSize: "cover",
  "z-index": -1,
}));

const useStyles = makeStyles({
  fileroot: {
    border: "1px solid #BABEC2",
    borderRadius: "5px",

    minHeight: "180px",
    "&:hover": {
      boxShadow: "0 0 1px 1px #E0E0E0",
      transitionDuration: "0.3s",
    },
    "&:focus": {
      border: "2px solid #28B294",
    },
  },
  item: {
    textAlign: "center !important",
  },
  details: {
    margin: "0 auto",
    paddingTop: "5px",
  },
  image: {
    width: "200px",
    height: "150px",
  },
});




const BoxThumb = styled("Box")(({ theme }) => ({
  minHeight: "75%",
  minWidth: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default function ActionAreaCard({
  file,
  settingId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
}) {
  const [open, setOpen] = React.useState(false);
  const snap = useSnapshot(widState);
  const classes = useStyles();
  const [thumbnailImage, setThumbnailUrl] = React.useState("");

  const [profileImage, setProfileImage] = React.useState();

  // const handleDisplay = (thumbnailImage) => {
  //   return (
  //     <BoxThumb sx={{ backgroundColor: "#F8F8F8" }}>
  //       <DescriptionOutlinedIcon sx={{ fontSize: "80px" }} />
  //     </BoxThumb>
  //   );
  // };

  
  React.useEffect(() => {
    if (!file) return;
    const fetchThumbnail = async () => {
      try {
        if (
          widState?.settingData?.[settingId]?.thumbnailUrls?.hasOwnProperty(
            file?.id
          )
        ) {
          setThumbnailUrl(
            (prev) =>
              widState.settingData?.[settingId]?.thumbnailUrls?.[file?.id]
          );
        } else {
          const response = await ApiCall.getThumbnailData(
            file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );

          var blodData = new Blob([response.data]);
          var urlData = URL.createObjectURL(blodData);
          widState.setThumbNail(settingId, file, urlData);
          setThumbnailUrl((prev) => urlData);
        }
      } catch (error) {
        setThumbnailUrl("error");
      }
    };
    fetchThumbnail();
  }, [file?.id]);
  
  const handleDisplay = (thumbnailImage) => {
    if (!thumbnailImage) {
      return (
        <BoxThumb>
          <CircularProgress color="secondary" />
        </BoxThumb>
      );
    } else if (thumbnailImage === "error") {
      return (
        <BoxThumb sx={{ backgroundColor: "#F8F8F8" }}>
          <DescriptionOutlinedIcon sx={{ fontSize: "80px" }} />
        </BoxThumb>
      );
    } else if (thumbnailImage) {
      return <CustomImage src={thumbnailImage} />;
    }
    // return (
    //   <BoxThumb>
    //     <CircularProgress />
    //   </BoxThumb>
    // );
  };

  const [len, setLen] = React.useState(16);
  let { nameLen } = useFileNameLength(file?.name);

  React.useEffect(() => {
    setLen(nameLen);
  }, [nameLen]);

  const getUserImage = async (file) => {
    // ;
    if (
      widState?.settingData?.[settingId]?.downloadUrls?.hasOwnProperty(file?.id)
    ) {
      snap.setLoading(false);
      setOpen(true);
      setProfileImage(
        widState.settingData?.[settingId]?.downloadUrls?.[file?.id]
      );
    } else {
      // // ;
      try {
        let res = await ApiCall.getImageResponse(
          file,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );
        // // ;
        var blobFile = new Blob([res?.data], {
          type: "application/octet-stream",
        });
        // // ;
        var fileURL = URL.createObjectURL(blobFile);
        // // ;
        snap.setLoading(false);
        widState.setDownloadedImage(settingId, file, fileURL);
        setOpen(true);
        setProfileImage(fileURL);
      } catch (error) {
        // // ;
      }

      // window.open(fileURL);
    }
  };

  const handleClickOpen = (file) => {
    snap.setLoading(true);
    getUserImage(file);
  };

  // const handleDisplay = (thumbnailImage) => {
  //   if (thumbnailImage) {
  //     return <CustomImage src={thumbnailImage} />;
  //   }
  //   return (
  //     <BoxThumb>
  //       <CircularProgress />
  //     </BoxThumb>
  //   );
  // };

  return (
    <Grid container className={classes.fileroot}>
      <Grid item container tabIndex="1" onClick={() => handleClickOpen(file)}>
        {handleDisplay(thumbnailImage)}
        <br />
        <Grid className={classes.details}>
          {len && (
            <Typography variant="h6">
              {`${file?.name?.substr(0, len)}...`}
            </Typography>
          )}
        </Grid>
      </Grid>
      {/* <div>{JSON.stringify(profileImage)}</div> */}
      <ModalPdf profileImage={profileImage} open={open} setOpen={setOpen} />
    </Grid>
  );
}
