import * as React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import ModalDecider from "../CommonSection/ModalDecider";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";
import { IconButton, Typography } from "@mui/material";
import DetailsView from "../CommonSection/DetailsView";
import { useTableSearch } from "../CustomHooks/UseTableSearch";

const columns = [
  {
    field: "name",
    type: "string",
    headerName: "Name",
    flex: 1,
  },

  { field: "type", type: "string", headerName: "Type", flex: 1 },
  { field: "size", type: "string", headerName: "Size", flex: 1 },
  { field: "modified_by", type: "string", headerName: "Modified By", flex: 1 },
  {
    field: "last_modified_time",
    type: "dateTime",
    headerName: "Last Modified Time",
    flex: 1,
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "created_time",
    type: "dateTime",
    headerName: "Created Time",
    flex: 1,
    valueGetter: ({ value }) => value && new Date(value),
  },
];

function DetailsSearchView({
  setDetailsViewPost,
  moveCopyData,
  pasteDatainDetailsView,
  searchedItem = [],
  handleClick,
  handleDoubleClick,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingId,
}) {
  const [rows, setRows] = useState([]);
  const [fileOpen, setFileOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [profileImage, setProfileImage] = React.useState(false);
  const [searchVal, setSearchVal] = useState("");
  const snap = useSnapshot(widState);
  const { filteredData, loading } = useTableSearch({
    searchVal,
    retrieve: searchedItem,
  });

  useEffect(() => {
    setRows((prev) => searchedItem);
  }, [searchedItem]);

  const getUserImage = async (file) => {
    if (
      widState.settingData?.[settingId]?.downloadUrls.hasOwnProperty(file?.id)
    ) {
      snap.setLoading(false);
      setFile((prev) => file);
      setFileOpen((prev) => true);
      setProfileImage(
        (prev) => widState.settingData?.[settingId]?.downloadUrls?.[file?.id]
      );
    } else {
      try {
        snap.setLoading(true);
        let response = await ApiCall.getImageResponse(
          file,
          connname,
          orgid,
          apikey,
          datacenterurl
        );

        let url = "";
        if (file?.type == "spreadsheet" || file?.type == "zohosheet") {
          console.log(file?.type);
          url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "text/csv;charset=utf-8",
            })
          );
        } else {
          console.log(file?.extn);
          url = window.URL.createObjectURL(new Blob([response.data]));
        }

        // var blobFile = new Blob([res.data]);
        // var fileURL = URL.createObjectURL(blobFile);
        widState.setDownloadedImage(settingId, file, url);
        setFile((prev) => file);
        setProfileImage((prev) => url);
        setFileOpen((prev) => true);
      } catch (error) {}
      snap.setLoading(false);
      // window.open(fileURL);
    }
  };

  return (
    <Box sx={{ width: "100%", ml: 1.1 }}>
      <ModalDecider
        open={fileOpen}
        setOpen={setFileOpen}
        profileImage={profileImage}
        file={file}
        datacenterurl={datacenterurl}
        settingId={settingId}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold", p: 0 }}>
          Global Search (Folders/ Sub Folders/ Files)
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid lightgrey",
            borderRadius: "20px",
            marginRight: "10px",
            height: "35px",
            backgroundColor: "#fff",
          }}
        >
          <IconButton sx={{ pl: "10px" }} aria-label="search">
            <SearchIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <InputBase
            sx={{
              ml: 1,
              pb: 0.3,
              // width: 140,
              "& input::placeholder": {
                fontSize: "14px",
              },
            }}
            className="search-input"
            placeholder="Type here to search"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Box>
      </Box>

      <DetailsView
        fromSearchView={true}
        post={searchedItem}
        filteredData={filteredData}
        handleClick={(row, rowId) => {
          handleDoubleClick(row);
          // handleClose()
        }}
        datacenterurl={datacenterurl}
        connname={connname}
        orgid={orgid}
        apikey={apikey}
        settingId={settingId}
        setDetailsViewPost={setDetailsViewPost}
        moveCopyData={moveCopyData}
        pasteDatainDetailsView={pasteDatainDetailsView}
        perPage={8}
      />

      {/* <DataGrid
        rows={rows}
        columns={columns}
        density={"compact"}
        disableColumnSelector={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 15, 25, 50, 100]}
        disableRowSelectionOnClick
        rowSelection={false}
        onCellDoubleClick={({
          cellMode,
          colDef,
          field,
          formattedValue,
          hasFocus,
          id,
          isEditable,
          row,
          rowNode,
          tabIndex,
          value,
        }) => {
          if (field == "name") {
            if (row?.type == "folder") {
              handleDoubleClick(row);
            } else {
              getUserImage(row);
            }
          }
        }}
      /> */}
    </Box>
  );
}

export default DetailsSearchView;
