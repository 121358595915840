import {
  Autocomplete,
  Button,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import * as ApiCalling from "../../Components/API/ApiCalling";
import useCrmVariable from "../../CustomHook/useCrmVariable";
import useField from "../../CustomHook/useField";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function ModuleSetting({
  row = {},
  bgcolor,
  index,
  editData,
  setEditData,
  moduleRelatedList,
  setModuleRelatedList,
}) {
  const [focus, setFocus] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [myValueState, setMyValueState] = useState({
    current: JSON.parse(row?.setting_schema)?.fieldApiName,
    prev: JSON.parse(row?.setting_schema)?.fieldApiName,
  });
  // const myValue = useRef(JSON.parse(row?.setting_schema)?.fieldApiName);
  const { admin, fields } = useField({ Entity: row?.module_name });
  const { orgid, connname, apikey, zuid, datacenterurl } = useCrmVariable({
    Entity: "",
    EntityId: "",
    crmVariableName: "",
  });

  const textRef = useRef(null);

  return (
    <>
      <TableRow
        key={index}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          bgcolor: bgcolor && "#F5F5F5",
        }}
      >
        <TableCell sx={{ border: "none", width: 300, height: 55 }}>
          <Typography>{row?.module_name}</Typography>
        </TableCell>
        <TableCell sx={{ border: "none", height: 55 }}>
          {!focus && (
            <Box
              onMouseOver={() => setMouseOver(true)}
              onMouseOut={(event) => {
                if (
                  textRef.current &&
                  textRef.current.contains(event.relatedTarget)
                ) {
                  return;
                } else {
                  setMouseOver((prev) => false);
                }
              }}
              ref={textRef}
              sx={{
                "&:hover": { border: "1px solid grey", cursor: "pointer" },
                ml: 1,
                py: 0.5,
                px: 1,
                borderRadius: "4px",
                display: "flex",
                justifyContent: "space-between",
                width: "81%",
              }}
              onClick={() => setFocus(true)}
            >
              <Typography>{myValueState.current?.display_name}</Typography>
              {mouseOver && (
                <EditRoundedIcon
                  sx={{
                    mr: 0.5,
                    mt: 0.2,
                    fontSize: 17,
                  }}
                />
              )}
            </Box>
          )}

          {focus && (
            <Box sx={{ display: "flex" }}>
              <Autocomplete
                disablePortal
                clearText="{null}"
                value={myValueState.current}
                onChange={(event, newValue) => {
                  // ;
                  // setValue((prev) => newValue);
                  if (newValue) {
                    setMyValueState({ ...myValueState, current: newValue });
                    // setMyValueState(newValue);
                  } else {
                    setMyValueState({ ...myValueState, current: null });
                  }
                  // else {
                  //   myValue.current = myValue.previous;
                  //   // setMyValueState(newValue);
                  // }
                  // setFieldApiName((prev) => newValue?.api_name);
                  // setRootFolderId(
                  //   (prev) => recordData?.[newValue?.api_name]
                  // );
                }}
                id="combo-box-demo"
                options={fields}
                size="small"
                fullWidth
                sx={{
                  // width: 250,
                  py: 1,
                  px: 1,
                  "& .MuiInputBase-input": {
                    height: "1.2rem",
                  },
                  "& .MuiAutocomplete-popper": {
                    backgroundColor: "red",
                  },
                }}
                // renderOption={(option) => (
                //   <Typography style={{ fontSize: "1.5rem" }}>{option.label}</Typography>
                // )}
                getOptionLabel={(option) => option.display_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Choose the Folder ID"
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14 },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        fontSize: "16px",
                      },
                    }}
                  />
                )}
              />
              {myValueState.current ? (
                <CheckRoundedIcon
                  onClick={async () => {
                    // upsertData(data: {} | undefined, connname: any, orgid: any, apikey: any, settingUrl?: string | undefined): Promise<any>

                    const settingData = {
                      settingId: moduleRelatedList[index]?.setting_id,
                      settingSchema: {
                        moduleApiName: moduleRelatedList[index]?.module_name,
                        fieldApiName: {
                          display_name: myValueState.current?.display_name,
                          api_name: myValueState.current?.api_name,
                        },
                        status: true,
                      },
                    };

                    // settingData = {
                    //   settingId: "97f9efbf-d36b-4142-962a-1921a101e2b5",
                    //   settingSchema: {
                    //     moduleApiName: "Contacts",
                    //     fieldApiName: {
                    //       display_name: "Last Name",
                    //       api_name: "Last_Name"
                    //     },
                    //     status: true,
                    //   },
                    // };

                    const upsertData = await ApiCalling.upsertData(
                      settingData,
                      connname,
                      orgid,
                      apikey,
                      datacenterurl
                    );

                    if (upsertData?.error) {
                      return "";
                    }

                    const tempSettingsArray = moduleRelatedList;
                    tempSettingsArray[index] = {
                      ...moduleRelatedList[index],
                      setting_schema: JSON.stringify({
                        moduleApiName: moduleRelatedList[index]?.module_name,
                        fieldApiName: {
                          display_name: myValueState.current?.display_name,
                          api_name: myValueState.current?.api_name,
                        },
                        status: true,
                      }),
                      fieldName: myValueState.current?.display_name,
                      moduleApiName: myValueState.current?.api_name,
                    };

                    setEditData((prev) => {});
                    setModuleRelatedList((prev) => tempSettingsArray);

                    setMyValueState({
                      ...myValueState,
                      prev: myValueState.current,
                    });
                    setMouseOver((prev) => false);
                    setFocus((prev) => false);
                  }}
                  // onClick={() =>{}}
                  sx={{
                    mt: 1.7,
                    mx: 0.5,
                    p: 0.1,
                    fontSize: 20,
                    // fontWeight: 'bold',
                    bgcolor: "#1e88e6",
                    borderRadius: "50%",
                    color: "#ffffff",
                    "&:hover": { cursor: "pointer" },
                  }}
                />
              ) : (
                <CheckRoundedIcon
                  sx={{
                    mt: 1.7,
                    mx: 0.5,
                    p: 0.1,
                    fontSize: 20,
                    // fontWeight: 'bold',
                    bgcolor: "#9e9e9e",
                    borderRadius: "50%",
                    color: "#ffffff",
                    // "&:hover": { cursor: "pointer" },
                  }}
                />
              )}

              <HighlightOffIcon
                onClick={() => {
                  setMyValueState({
                    ...myValueState,
                    current: myValueState.prev,
                  });
                  setFocus((prev) => false);
                  setMouseOver((prev) => false);
                }}
                sx={{
                  mt: 1.7,
                  color: "#c91228",
                  "&:hover": { cursor: "pointer" },
                }}
              />
            </Box>
          )}
        </TableCell>
        {/* <TableCell sx={{ border: "none" }} align="right">
          <div>
            {editData?.setting_id != row?.setting_id ? (
              <p>{myValue.current?.display_name}</p>
            ) : (
              // <>Edited</>
              <Autocomplete
                disablePortal
                value={myValue?.current}
                onChange={(event, newValue) => {
                  
                  // setValue((prev) => newValue);
                  myValue.current = newValue;
                  // setFieldApiName((prev) => newValue?.api_name);
                  // setRootFolderId(
                  //   (prev) => recordData?.[newValue?.api_name]
                  // );
                }}
                id="combo-box-demo"
                options={fields}
                sx={{
                  width: 250,
                  py: 1,
                  px: 1,
                  "& .MuiInputBase-input": {
                    height: "1.2rem",
                  },
                  "& .MuiAutocomplete-popper": {
                    backgroundColor: "red",
                  },
                }}
                // renderOption={(option) => (
                //   <Typography style={{ fontSize: "1.5rem" }}>{option.label}</Typography>
                // )}
                getOptionLabel={(option) => option.display_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose the Folder ID"
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        fontSize: "16px",
                      },
                    }}
                  />
                )}
              />
            )}

            {editData?.setting_id != row?.setting_id ? (
              <Button
                onClick={() => {
                  
                  setEditData((prev) => moduleRelatedList[index]);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  // upsertData(data: {} | undefined, connname: any, orgid: any, apikey: any, settingUrl?: string | undefined): Promise<any>

                  const settingData = {
                    settingId: moduleRelatedList[index]?.setting_id,
                    settingSchema: {
                      moduleApiName: moduleRelatedList[index]?.module_name,
                      fieldApiName: {
                        display_name: value?.display_name,
                        api_name: value?.api_name,
                      },
                      status: true,
                    },
                  };

                  const upsertData = await ApiCalling.upsertData(
                    settingData,
                    connname,
                    orgid,
                    apikey
                  );

                  if (upsertData?.error) {
                    return "";
                  }

                  const tempSettingsArray = moduleRelatedList;
                  tempSettingsArray[index] = {
                    ...moduleRelatedList[index],
                    setting_schema: JSON.stringify({
                      moduleApiName: moduleRelatedList[index]?.module_name,
                      fieldApiName: {
                        display_name: value?.display_name,
                        api_name: value?.api_name,
                      },
                      status: true,
                    }),
                    fieldName: value?.display_name,
                    moduleApiName: value?.api_name,
                  };
                  
                  
                  setEditData((prev) => {});
                  setModuleRelatedList(tempSettingsArray);

                  
                }}
              >
                Save
              </Button>
            )}
          </div>
        </TableCell> */}
      </TableRow>
    </>
  );
}

export default ModuleSetting;
