import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useEffect, useState } from "react";
import Overview from "../Components/Overview/Overview";
import SettingsPage from "../Components/SettingsPage/SettingsPage";
import axios from "axios";
import { useSnapshot } from "valtio";
import { widState } from "../Store/workdriveState";
import logo from "../Images/easypluginz-label.png";
import FAQ from "../Components/FAQ/FAQ";
import workdrive_to_crm_logo from "../Images/workdrive-to-crm-logo.png";
import ModulesRelatedFieldSetting from "../Components/ModuleRelatedSetting/ModulesRelatedFieldSetting";

const ZOHO = window.ZOHO;

export default function Settings() {
  const widSnap = useSnapshot(widState);
  const [loading, setLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(false);
  const [auth, setAuth] = useState("Active");
  const [settings, setSettings] = useState("Inactive");
  const [faq, setFaq] = useState("Inactive");
  const [moduleRelatedSetting, setModuleRelatedSetting] = useState("Inactive");
  const [apiKey, setApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zuid, setZuid] = useState(null);

  const [zohoLoaded, setZohoLoaded] = useState(false);

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const currentEnv = await ZOHO.CRM.CONFIG.GetCurrentEnvironment();
        const dataCenterMap = {
          US: "https://www.zohoapis.com",
          EU: "https://www.zohoapis.eu",
          AU: "https://www.zohoapis.com.au",
          IN: "https://www.zohoapis.in",
          China: "https://www.zohoapis.com.cn",
          JP: "https://www.zohoapis.jp",
        };
        setDataCenterUrl(dataCenterMap?.[currentEnv?.deployment] || "");
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  const handleAuthenticate = async () => {
    setAuthLoading(true);
    const crmApiDomain = dataCenterUrl;
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/authenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: apiKey,
        zapiKey: zapiKey,
        accountsUrl:
          "https://accounts.zoho." +
          dataCenterUrl?.split("https://www.zohoapis.")?.[1],
        connName: "easyworkdriveforcrm",
        scope: [
          "ZohoSearch.securesearch.READ",
          "WorkDrive.collection.CREATE",
          "WorkDrive.collection.READ",
          "WorkDrive.collection.UPDATE",
          "WorkDrive.comments.CREATE",
          "WorkDrive.comments.READ",
          "WorkDrive.comments.UPDATE",
          "WorkDrive.DataTemplates.CREATE",
          "WorkDrive.DataTemplates.UPDATE",
          "WorkDrive.files.ALL",
          "WorkDrive.files.UPDATE",
          "WorkDrive.files.READ",
          "WorkDrive.files.CREATE",
          "WorkDrive.files.DELETE",
          "WorkDrive.users.READ",
          "WorkDrive.groups.CREATE",
          "WorkDrive.groups.READ",
          "WorkDrive.groups.UPDATE",
          "WorkDrive.members.CREATE",
          "WorkDrive.members.READ",
          "WorkDrive.members.UPDATE",
          "WorkDrive.organization.READ",
          "WorkDrive.team.CREATE",
          "WorkDrive.team.READ",
          "WorkDrive.team.UPDATE",
          "WorkDrive.teamfolders.admin.READ",
          "WorkDrive.teamfolders.CREATE",
          "WorkDrive.teamfolders.READ",
          "WorkDrive.teamfolders.UPDATE",
          "WorkDrive.teamfolders.sharing.CREATE",
          "WorkDrive.teamfolders.sharing.READ",
          "WorkDrive.teamfolders.sharing.UPDATE",
          "WorkDrive.workspace.READ",
          "WorkDrive.workspace.UPDATE",
          "WorkDrive.workspace.CREATE",
          "ZohoCRM.users.CREATE",
          "ZohoCRM.users.READ",
          "ZohoCRM.users.UPDATE",
          "ZohoCRM.org.READ",
          "ZohoCRM.org.UPDATE",
          "ZohoCRM.settings.CREATE",
          "ZohoCRM.settings.READ",
          "ZohoCRM.settings.UPDATE",
          "ZohoCRM.coql.READ",
          "ZohoCRM.modules.CREATE",
          "ZohoCRM.modules.READ",
          "ZohoCRM.modules.UPDATE",
        ],
        redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/callback`,
        crmApiDomain: crmApiDomain,
        zuid: zuid,
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const authCheckStatus = await handleAuthCheck({
        headers: {
          apikey: apiKey,
          connname: "easyworkdriveforcrm",
          orgid: orgId,
          accountsurl: crmApiDomain,
          dataCenterurlvariablename: "easyworkdriveforcrm__Data_Center_URL",
        },
        dataCenterUrl: crmApiDomain,
      });
      if (authCheckStatus) {
        // Create Setting for WorkDrive
        // BUG we may need to activate it later

        const workdriveZuid = await axios.request({
          url: `${process.env.REACT_APP_API_SERVER_URL}/zohoworkdrive/getzuid`,
          method: "GET",
          headers: {
            apikey: apiKey,
            connname: "easyworkdriveforcrm",
            orgid: orgId,
            datacenterurl: crmApiDomain,
          },
        });

        const zuidData = {
          apiname: "easyworkdriveforcrm__ZUID",
          value: workdriveZuid?.data?.data || "",
        };
        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", zuidData);
        setZuid(workdriveZuid?.data?.data || zuid);

        const defaultFoldersCreated = await axios.request({
          url: `${process.env.REACT_APP_API_SERVER_URL}/zohoworkdrive/${zuid}/extfolders`,
          method: "POST",
          data: {},
          headers: {
            apikey: apiKey,
            connname: "easyworkdriveforcrm",
            orgid: orgId,
            datacenterurl: crmApiDomain,
          },
        });

        setSettings("Active");
        setAuth("Successful");
        setAuthLoading(false);
        clearInterval(myAuthCheckInterval);
        widState.authCheck = true;
      } else {
        setSettings("Inactive");
        setAuth("Active");
        setAuthLoading(false);
        clearInterval(myAuthCheckInterval);
        widState.authCheck = false;
      }
    }, 12000);

    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );
    if (window.focus) {
      newwindow.focus();
    }
  };

  const handleAuthCheck = async ({ headers, dataCenterUrl }) => {
    const authCheck = await axios({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/verifyauth`,
      headers: headers,
    });
    if (authCheck?.data?.org?.length > 0) {
      if (dataCenterUrl) {
        const createExtension = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
          method: "POST",
          data: {
            widgetName: "Workdrive by Easy Pluginz",
            widgetUrl:
              "https://widgets.v1.workdriveforcrm.easy-pluginz.com/relatedlist",
            widgetDescription:
              "This widget you can utilizet in any Standard Module and Custom Module to visualize, crate, edit, delete folders and to crete shared links",
            widgetType: "RELATED_LIST",
            apiDomain: dataCenterUrl,
          },
          headers: {
            apikey: headers?.apikey,
            connname: "easyworkdriveforcrm",
            datacenterurl: dataCenterUrl,
            orgid: headers?.orgid,
          },
        });
        // Create Default Settings
        const createSettings = await axios.request({
          url: `${process.env.REACT_APP_API_SERVER_URL}/zohoworkdrive/extrelatedsettings`,
          method: "GET",
          headers: {
            apikey: headers?.apikey,
            datacenterurl: dataCenterUrl,
            connname: "easyworkdriveforcrm",
            orgid: headers?.orgid,
          },
        });
      }

      return true;
    } else {
      return false;
    }
  };

  const handleRevoke = async () => {
    setAuthLoading(true);
    const crmApiDomain = dataCenterUrl;
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/revoke",
      method: "GET",
      headers: {
        orgid: orgId,
        apikey: apiKey,
        connName: "easyworkdriveforcrm",
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const authCheckStatus = await handleAuthCheck({
        headers: {
          apikey: apiKey,
          connname: "easyworkdriveforcrm",
          orgid: orgId,
        },
      });

      if (authCheckStatus) {
        setSettings("Active");
        setAuth("Successful");
        setAuthLoading(false);
        clearInterval(myAuthCheckInterval);
        widState.authCheck = true;
      } else {
        setSettings("Inactive");
        setAuth("Active");
        setAuthLoading(false);
        clearInterval(myAuthCheckInterval);
        widState.authCheck = false;
      }
    }, 2000);
  };

  const fetchOrgVariablesData = async () => {
    /**
     * Get Organization Variables
     */
    const orgData = {
      apiKeys: [
        "easyworkdriveforcrm__API_KEY",
        "easyworkdriveforcrm__Organization_ID",
        "easyworkdriveforcrm__ZAPI_Key",
        "easyworkdriveforcrm__Data_Center_URL",
        "easyworkdriveforcrm__ZUID",
      ],
    };
    const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

    setDataCenterUrl(
      orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
        ?.value !== "null"
        ? orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
            ?.value
        : dataCenterUrl
    );
    widState.dataCenterUrl =
      orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
        ?.value !== "null"
        ? orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
            ?.value
        : dataCenterUrl;
    setApiKey(
      orgVariables?.Success?.Content?.easyworkdriveforcrm__API_KEY?.value
    );
    widState.apiKey =
      orgVariables?.Success?.Content?.easyworkdriveforcrm__API_KEY?.value;
    setOrgId(
      orgVariables?.Success?.Content?.easyworkdriveforcrm__Organization_ID
        ?.value
    );
    widState.orgId =
      orgVariables?.Success?.Content?.easyworkdriveforcrm__Organization_ID?.value;
    setZapiKey(
      orgVariables?.Success?.Content?.easyworkdriveforcrm__ZAPI_Key?.value
    );

    const headers = {
      apikey:
        orgVariables?.Success?.Content?.easyworkdriveforcrm__API_KEY?.value,
      connname: "easyworkdriveforcrm",
      orgid:
        orgVariables?.Success?.Content?.easyworkdriveforcrm__Organization_ID
          ?.value,
      accountsurl:
        orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
          ?.value !== "null"
          ? orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
              ?.value
          : dataCenterUrl,
      dataCenterurlvariablename: "easyworkdriveforcrm__Data_Center_URL",
    };
    // ;
    /**
     * Fetch User Details
     */
    // const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();

    // setZuid(orgResp?.org?.[0]?.primary_zuid);
    // widState.zuid = orgResp?.org?.[0]?.primary_zuid;
    setZuid(orgVariables?.Success?.Content?.easyworkdriveforcrm__ZUID?.value);
    widState.zuid =
      orgVariables?.Success?.Content?.easyworkdriveforcrm__ZUID?.value;

    const authCheckStatus = await handleAuthCheck({
      headers: headers,
      dataCenterUrl:
        orgVariables?.Success?.Content?.easyworkdriveforcrm__Data_Center_URL
          ?.value,
    });
    if (authCheckStatus) {
      setSettings("Active");
      setAuth("Successful");
      widState.authCheck = true;
    } else {
      setSettings("Inactive");
      setAuth("Active");
      widState.authCheck = false;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (zohoLoaded) {
      fetchOrgVariablesData();
    }
  }, [zohoLoaded]);

  if (!zohoLoaded) {
    return (
      <Box
        sx={{
          p: 2,
        }}
      >
        Data Not loaded yet.
      </Box>
    );
  }

  // //

  if (!zohoLoaded || loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Please Wait While Fetching Data...{" "}
        </Typography>
        <CircularProgress sx={{ mt: 1 }} size={26} />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          bgcolor: "#F6F8FB",
          minHeight: "100vh",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            py: "12px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <h3 style={{marginLeft: "150px"}} ></h3> */}
          {/* <Typography
            sx={{
              mt: 1,
              mb: 1,
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "32px",
              fontWeight: 600,
              marginLeft: "160px"
            }}
          >
            WorkDrive to CRM
          </Typography> */}
          <Box sx={{ marginLeft: "160px" }}>
            <img
              src={workdrive_to_crm_logo}
              width={154}
              alt="workdrive to crm logo"
            />
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #1976D2",
              borderRadius: "5px",
              px: 2,
              my: 1,
              mr: 1,
            }}
          >
            <PlayCircleOutlineIcon sx={{ color: "#1976D2", mr: 1 }} />
            <a
              href="www.google.com"
              style={{
                textDecoration: "none",
                color: "#1976D2",
                fontWeight: "500",
              }}
            >
              {" "}
              View Tutorial
            </a>
          </Box> */}
        </Box>
        <Divider />

        <Box
          sx={{
            mt: 4,
            mb: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {auth === "Active" ? (
            <Box
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {/* <Box
                sx={{
                  bgcolor: "#1F6DDE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 0.4,
                  borderRadius: "50%",
                  height: "35px",
                  width: "35px",
                  mr: 1,
                }}
              >
                <ViewModuleIcon sx={{ color: "white", fontSize: 19 }} />
              </Box>{" "} */}
              Authentication
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Inactive");
                setModuleRelatedSetting((prev) => "Inactive");
                setAuth((prev) => "Active");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
                width: 200,
              }}
            >
              Authenticated
            </Box>
          )}
          {settings === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              Module Settings
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setModuleRelatedSetting((prev) => "Inactive");
                setSettings((prev) => "Active");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Module Settings
            </Box>
          )}
          {moduleRelatedSetting === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              Related List Settings
            </Box>
          ) : (
            <Box
              onClick={() => {
                setModuleRelatedSetting((prev) => "Active");
                setFaq((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Related List Settings
            </Box>
          )}
          {/* {faq === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              FAQ
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Active");
                setModuleRelatedSetting((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              FAQ
            </Box>
          )} */}
        </Box>

        {auth === "Active" && (
          <Overview
            // handleChange={handleChange}
            authCheck={widSnap.authCheck}
            authLoading={authLoading}
            setDataCenterUrl={setDataCenterUrl}
            handleAuthenticate={handleAuthenticate}
            dataCenterUrl={dataCenterUrl}
            handleRevoke={handleRevoke}
          />
        )}

        {settings === "Active" && <SettingsPage />}
        {moduleRelatedSetting === "Active" && <ModulesRelatedFieldSetting />}
        {faq === "Active" && <FAQ />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: 2,
            my: 2,
            // position: "fixed",
            // zIndex: -1,
            // right: 2,
            // bottom: 1,
          }}
        >
          <img
            src={logo}
            alt="Easy plugins logo"
            height={40}
            // style={{ marginLeft: "90px" }}
          />
        </Box>
      </Box>
    );
  }
}
