import { fileNameLength, folderNameLength } from "./Constant";

export const changeName = (response, file) => {
  
  return {
    ...file,
    name: response?.data?.name,
  };
};

export const changeParent = (response, file) => {
  // return {
  //     ...file,
  //     parent_id: response?.data?.name
  // }
};

export default function getThumbnail() {
  return "Name";
}

export const handleFileNameLength = (file) => {
  if (file?.name?.length > fileNameLength) {
    return `${file?.name?.substr(0, fileNameLength)}...`;
  }
  return file?.name;
};

export const handleFolderNameLength = (folder) => {
  if (folder?.name?.length > folderNameLength) {
    return `${folder?.name?.substr(0, folderNameLength)}...`;
  }
  return folder?.name;
};
