import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import folderTree from "../../../folderTree.svg";
import "./AddCustomModule.css";
import RootFolderSelection from "../RootFolderSelection/RootFolderSelection";
import { useSnapshot } from "valtio";
import { widState } from "../../../Store/workdriveState";
import AddIcon from "@mui/icons-material/Add";
const ZOHO = window.ZOHO;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 550,
  borderRadius: "20px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  py: 4,
};

export default function AddCustomModule({ datacenterurl }) {
  const snap = useSnapshot(widState);
  const [activeClass, setActiveClass] = useState("Leads");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setTriggerFetch(!triggerFetch);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [defaultModule, setDefaultModule] = useState([
    "Leads",
    "Contacts",
    "Accounts",
    "Deals",
    "Campaigns",
  ]);

  const [triggerFetch, setTriggerFetch] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [selectedModuleValue, setSelectedModuleValue] = useState(null);
  const [selectedFieldValue, setSelectedFieldValue] = useState(null);
  const [firstCreateBtnHit, setFirstCreateBtnHit] = useState(false);

  useEffect(() => {
    async function initZoho() {
      ZOHO.CRM.META.getModules().then(function (data) {
        let resp = data?.modules;
        let finalResp = resp?.map((item) => {
          if (item.module_name && item.plural_label) {
            return {
              displayName: item.plural_label,
              apiName: item.api_name,
            };
          }
        });
        const filteredResp = finalResp?.filter((item) => Boolean(item));
        setModuleList(filteredResp);
      });
    }
    initZoho();
  }, [triggerFetch]);

  const handleFieldFetch = async (apiName) => {
    ZOHO.CRM.META.getFields({ Entity: apiName }).then(function (data) {
      // // ;
      const resp = data?.fields;
      let finalResp = resp?.map((item) => {
        if (item.field_label && item.api_name && item.data_type === "text") {
          return {
            displayName: item.field_label,
            apiName: item.api_name,
          };
        }
      });
      const filteredResp = finalResp?.filter((item) => Boolean(item));
      setFieldList(filteredResp);
    });
  };

  const handleCreateCustomModule = () => {
    setFirstCreateBtnHit((prev) => true);
    if (selectedModuleValue && selectedFieldValue) {
      let modules = defaultModule;
      modules.push(selectedModuleValue.displayName);
      setDefaultModule([...new Set(modules)]);
      handleClose();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // py: 1,
          px: 7,
          // bgcolor: "rgba(24, 80, 160, 0.04)",
        }}
      >
        <Box sx={{ bgcolor: "white", borderRadius: "20px" }}>
          {Object.keys(snap.settingData)?.map((module) => {
            return (
              <button
                onClick={() => widState.setSelectedSetting(module)}
                className={
                  snap?.selectedSetting === `${module}`
                    ? "activeModuleBtn"
                    : "moduleBtn"
                }
              >
                {module}
              </button>
            );
          })}
        </Box>
        {/* <Button startIcon={<AddIcon />} variant="outlined">Add Custom Module</Button> */}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ mx: 5.5 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  p: 0.5,
                  cursor: "pointer",
                  "&:hover": { bgcolor: "rgba(0, 0, 0, 0.06)" },
                }}
              >
                <ClearIcon
                  sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 27 }}
                />
              </Box>
            </Box>
            <Box sx={{ mr: 2, mt: 3.7 }}>
              <Grid alignItems="center" container maxWidth="lg">
                <Grid item xs={4} sx={{ mb: 3 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Module name
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ mb: 3 }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    loading={moduleList.length ? false : true}
                    options={moduleList}
                    getOptionLabel={(option) => option.displayName}
                    value={selectedModuleValue}
                    onChange={(e, v) => {
                      if (v) {
                        handleFieldFetch(v.apiName);
                        setSelectedModuleValue(v);
                        setSelectedFieldValue(null);
                      } else {
                        setSelectedModuleValue(null);
                        selectedFieldValue(null);
                      }
                    }}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        error={
                          firstCreateBtnHit && !selectedModuleValue
                            ? true
                            : false
                        }
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sx={{ mb: 3 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Field name
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ mb: 3 }}>
                  <Autocomplete
                    loading={fieldList.length ? false : true}
                    disablePortal
                    id="combo-box-demo"
                    options={fieldList}
                    getOptionLabel={(option) => option.displayName}
                    value={selectedFieldValue}
                    onChange={(e, v) => {
                      if (v) {
                        setSelectedFieldValue(v);
                      } else {
                        setSelectedFieldValue(null);
                      }
                    }}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        error={
                          firstCreateBtnHit && !selectedFieldValue
                            ? true
                            : false
                        }
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sx={{ mb: 3 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Root Folder ID
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ mb: 3 }}>
                  <RootFolderSelection datacenterurl={datacenterurl} />
                </Grid>
                <Grid item xs={4} sx={{ mb: 3 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Folder Structure{" "}
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ mb: 3 }}>
                  <img src={folderTree} />
                </Grid>
              </Grid>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                <Button
                  onClick={handleClose}
                  sx={{
                    textTransform: "none",
                    width: "6rem",
                    borderRadius: "4px",
                    height: "2.3rem",
                    color: "#1850A0",
                    border: "none",
                    bgcolor: "transparent",
                    mr: 2,
                    "&:hover": { border: "none", bgcolor: "#cfd7e6" },
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCreateCustomModule}
                  sx={{
                    width: "12rem",
                    borderRadius: "4px",
                    textTransform: "none",
                    height: "2.3rem",
                    bgcolor: "#1850A0",
                  }}
                  variant="contained"
                >
                  Save Related List Setting
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
